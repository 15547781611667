import { ClubFormType, Event } from '../models/app.models';
import { format } from 'date-fns';
import { getEventId } from './helpers';
import { ClubDetails } from '../../features/club/models/club-details.interface';

export function mapEventToEventInformation(event: Event) {
  return {
    id: event.id,
    name: event.name,
    startDate: format(new Date(event.startDate), 'yyyy-MM-dd'),
    endDate: format(new Date(event.endDate), 'yyyy-MM-dd'),
    startTime: event.startTime,
    endTime: event.endTime,
    fromPrice: event.fromPrice.replace('.-', ''),
    pressText: event.pressText,
    ticketLink: event.ticketLink,
    ageRestrictionWomen: event.ageRestrictionMen,
    ageRestrictionMen: event.ageRestrictionWomen,
    fyraTicketLink: event.fyraTicketLink,
    location: event.location?.id || -1,
    internalLabel: event.internalLabel?.id || -1,
    locationName: event.locationName,
    locationStreet: event.locationStreet,
    locationPlz: event.locationPlz,
    locationProvince: event.locationProvince,
    eventId: getEventId(event),
    genres: event.genres.map((genre) => genre.id),
    tags: event.tags.map((tag) => tag.id),
    flyer: event.flyer,
  };
}

export function mapClubFormToClubDetails(clubForm: ClubFormType): ClubDetails {
  return {
    id: clubForm.id,
    name: clubForm.location.name ?? '',
    email: clubForm.contact.email ?? '',
    phoneNumber: clubForm.contact.phoneNumber ?? '',
    description: clubForm.location.description ?? '',
    location: {
      id: clubForm.location.id ?? -1,
      name: clubForm.location.name ?? '',
      place: clubForm.location.place ?? '',
      street: clubForm.location.street ?? '',
      region: clubForm.location.region ?? '',
    },
    instagramUrl: clubForm.socials.instagramUrl ?? '',
    facebookUrl: clubForm.socials.facebookUrl ?? '',
    twitterUrl: clubForm.socials.twitterUrl ?? '',
    websiteUrl: clubForm.socials.websiteUrl ?? '',
    logoUrl: clubForm.logo.logoUrl ?? '',
    lounges: [],
    packages: [],
    status: 'pending',
    clubId: clubForm.clubId,
  };
}
